$(function() {


    // $('#date').datetimepicker({
    //        format: 'YYYY-MM-DD hh:mm:ss'
    //    });

    $('#report').on('change', function() {
        if (this.value == 1) {
            //first report
        }

        if (this.value == 2) {
            //first report
        }
        if (this.value == 3) {
            //first report
        }




    });

    $('.deletebtn').click(function(e) {
        e.preventDefault();
        var result = confirm("אתה בטוח רוצה למחוק את ההזמנה");
        if (result) {
            var $_GET = {};
            if (document.location.toString().indexOf('?') !== -1) {
                var query = document.location
                    .toString()
                    // get the query string
                    .replace(/^.*?\?/, '')
                    // and remove any existing hash string (thanks, @vrijdenker)
                    .replace(/#.*$/, '')
                    .split('&');

                for (var i = 0, l = query.length; i < l; i++) {
                    var aux = decodeURIComponent(query[i]).split('=');
                    $_GET[aux[0]] = aux[1];
                }
            }


            var sendurl = "index.php?dir=site&page=members&op=orders&id=" + $_GET['id'];
            var ajaxurl = $(this).attr('data-button');
            $.ajax({
                url: ajaxurl,
                method: "get",
                success: function(data) {
                    // alert(data);
                    window.location = sendurl;

                },
                error: function(request, status, error) {
                    alert(request.responseText);
                }
            });

        }



    });





    $("#searchdate").click(function(e) {
        e.preventDefault();
        var datefrom = $("#datefrom").val();
        var dateto = $("#dateto").val();
        // alert( datefrom  + dateto);


        var $_GET = {};
        if (document.location.toString().indexOf('?') !== -1) {
            var query = document.location
                .toString()
                // get the query string
                .replace(/^.*?\?/, '')
                // and remove any existing hash string (thanks, @vrijdenker)
                .replace(/#.*$/, '')
                .split('&');

            for (var i = 0, l = query.length; i < l; i++) {
                var aux = decodeURIComponent(query[i]).split('=');
                $_GET[aux[0]] = aux[1];
            }
        }

        var ajaxurl = "index.php?dir=site&page=members&op=ajaxsearchdate&id=" + $_GET['id'];

        if (datefrom != '' && dateto != '') {
            $.ajax({
                url: ajaxurl,
                method: "POST",
                data: {
                    datefrom: datefrom,
                    dateto: dateto
                },
                success: function(data) {
                    // alert(data);
                    $("#resspage").hide();
                    $("#tableress").hide().html(data).fadeIn('fust');

                },
                error: function(request, status, error) {
                    alert(request.responseText);
                }
            });


        } else {
            alert('מלא את תאריכי החיפוש בבקשה');
        }

        //alert($_GET['id']);


        return false;

    });


    $("#addbtn").click(function(e) {
        e.preventDefault();
        var prodactamount = [];
        var prodactname = [];
        var prodactcs = [];
        var prodactprice = [];
        var date = $("#date").val();
        var area = $("#area").val();

        $('input[name^="prodact"]').each(function() {
            prodactamount.push($(this).val());
        });
        $('input[name^="cs"]').each(function() {
            prodactcs.push($(this).val());
        });
        $('input[name^="name"]').each(function() {
            prodactname.push($(this).val());
        });
        $('input[name^="price"]').each(function() {
            prodactprice.push($(this).val());
        });

        // jprodactamount = JSON.stringify(prodactamount);
        // jprodactname = JSON.stringify(prodactname);
        //
        //alert( prodactamount  + prodactname + date +  area);
        //
        //

        var $_GET = {};
        if (document.location.toString().indexOf('?') !== -1) {
            var query = document.location
                .toString()
                // get the query string
                .replace(/^.*?\?/, '')
                // and remove any existing hash string (thanks, @vrijdenker)
                .replace(/#.*$/, '')
                .split('&');

            for (var i = 0, l = query.length; i < l; i++) {
                var aux = decodeURIComponent(query[i]).split('=');
                $_GET[aux[0]] = aux[1];
            }
        }

        var ajaxurl = "index.php?dir=site&page=members&op=ajaxadd&id=" + $_GET['id'];
        var sendurl = "index.php?dir=site&page=members&op=orders&id=" + $_GET['id'];

        //alert($_GET['id']);

        $.ajax({
            url: ajaxurl,
            method: "POST",
            data: {
                date: date,
                area: area,
                prodactamount: prodactamount,
                prodactname: prodactname,
                prodactcs: prodactcs
            },
            success: function(data) {
                window.location = sendurl;
            },
            error: function(request, status, error) {
                alert(request.responseText);
            }
        });

        return false;
    });
});
